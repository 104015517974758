<template>
  <div>
    <div class="d-flex login-container">
      <div class="login-form d-flex align-items-center position-relative col-sm-12 col-lg-7">
        <div class="single-column-form-wrap w-100 mx-auto px-5 d-flex flex-column">
          <div class="mx-auto mb-4 d-flex flex-column align-items-center">
            <img
              :src="`${genuityLogoSrc}`"
              alt="genuity-logo"
              height="120"
              width="273"
            >
            <div v-if="authenticating">
              Redirecting...
            </div>
          </div>
          <div v-show="!authenticating">
            <form
              accept-charset="UTF-8"
              @submit.prevent="submitUser"
            >
              <div class="form-group mb-5">
                <label
                  for="email"
                  data-tc-label="email"
                >
                  Email
                </label>
                <input
                  id="email"
                  ref="emailInput"
                  v-model="email"
                  v-validate="'required|email'"
                  type="email"
                  class="form-control"
                  name="email"
                  placeholder="yourname@company.com"
                  autocomplete="email"
                  :class="{'is-invalid': errors.has('email')}"
                  data-tc-email-field
                >
                <span
                  v-show="errors.has('email')"
                  class="form-text text-danger small"
                >{{ errors.first('email') }}</span>
              </div>

              <div class="form-group mb-2 position-relative">
                <label
                  for="password"
                  data-tc-label="password"
                >
                  Password
                </label>
                <div class="d-inline">
                  <input
                    id="password"
                    ref="passwordInput"
                    v-model="password"
                    v-validate="'required|min:8'"
                    class="form-control"
                    name="password"
                    autocomplete="password"
                    placeholder="Enter your password"
                    :class="{'is-invalid': errors.has('password')}"
                    :type="showPassword ? 'text' : 'password'"
                    data-tc-password-field
                  >
                  <span
                    class="show-password"
                    @click="showPassword = !showPassword"
                  >
                    <img
                      v-if="!showPassword"
                      src="https://nulodgic-static-assets.s3.amazonaws.com/images/show-password.png"
                      width="16"
                      height="10"
                    >
                    <img
                      v-else
                      src="https://nulodgic-static-assets.s3.amazonaws.com/images/hide-password.png"
                      width="16"
                      height="10"
                    >
                  </span>
                </div>
                <span
                  v-show="errors.has('password')"
                  class="form-text text-danger small"
                >
                  {{ errors.first('password') }}
                </span>
              </div>
              <div class="text-right mb-5 w-100 d-inline-block">
                <a
                  href=""
                  @click.prevent="$router.push('/forgot_password')"
                >Forgot Password?</a>
              </div>
              <div
                v-if="runCaptcha"
                class="text-center mb-5"
              >
                <vue-recaptcha
                  ref="recaptcha"
                  size="invisible"
                  :sitekey="recaptchaSiteKey"
                  :load-recaptcha-script="true"
                  @verify="verifyCaptcha"
                  @expired="captchaExpired"
                  @error="captchaError"
                >
                  <button
                    class="btn btn-primary btn-block"
                    type="submit"
                    :disabled="isSubmitting"
                    data-tc-login-btn
                  >
                    Log In
                  </button>
                </vue-recaptcha>
              </div>
              <div
                v-else
                class="text-center mb-2"
              >
                <button
                  class="btn btn-primary btn-block"
                  type="submit"
                  :disabled="isSubmitting"
                  data-tc-login-btn
                >
                  Log In
                </button>
              </div>
            </form>

            <div class="text-center">
              <div class="signup-google-text text-secondary not-as-small position-relative my-5">
                OR
              </div>
              <div class="text-center mt-5">
                <button
                  class="btn gsuite-btn btn-block text-themed-base"
                  @click="gsuiteLogin"
                >
                  <img 
                    src="https://nulodgic-static-assets.s3.amazonaws.com/images/logos/google-icon.svg"
                    height="22"
                    class="mr-2"
                  >
                  Sign in with Google
                </button>
              </div>
              <div 
                v-if="microsoftSSOEnabled"
                class="text-center mt-3"
              >
                <button
                  class="btn gsuite-btn btn-block text-themed-base"
                  @click="microsoftLogin"
                >
                  <img 
                    src="https://nulodgic-static-assets.s3.amazonaws.com/images/logos/microsoft.png"
                    height="22"
                    class="mr-2"
                  >
                  Sign in with Microsoft
                </button>
              </div>
            </div>
            <div class="text-secondary not-as-small my-4 text-center">
              <span class="d-inline-block align-middle">Don't have an account yet?</span>
              <a
                href="/users/sign_up"
                class="btn btn-link btn-sm d-inline-block align-middle text-underline"
              >Sign up</a>
            </div>
          </div>
        </div>
      </div>
      <quotes />
    </div>
  </div>
</template>

<script>
  import http from 'common/http';
  import Cookies from 'js-cookie';
  import VueRecaptcha from 'vue-recaptcha';
  import strings from 'mixins/string';
  import { mapMutations, mapGetters } from 'vuex';
  import Quotes from "./quotes";

  export default {
    $_veeValidate: {
      validator: 'new',
    },
    components: {
      Quotes,
      VueRecaptcha,
    },
    mixins: [strings],
    data() {
      return {
        email: '',
        password: '',
        isSubmitting: false,
        gsuiteLoginPath: null,
        showPassword: false,
        state: this.getRedirectionUrl(),
        companySubdomain: null,
        microsoftSSOEnabled: false,
        authenticating: false,
      };
    },
    computed: {
      ...mapGetters('GlobalStore', [
        'isRobot',
        'runCaptcha',
        'recaptchaSiteKey',
      ]),
      genuityLogoSrc() {
        if (this.$siteTheme === "dark") {
          return "https://nulodgic-static-assets.s3.amazonaws.com/images/logo-white.svg";
        }
        return "https://nulodgic-static-assets.s3.amazonaws.com/images/logo.svg";
      },
    },
    mounted() {
      this.fetchGsuiteLoginPath();
      this.getDomainFromCookies();
      this.submituserWithAccessId();
      this.getRedirectionUrl();
    },
    methods: {
      ...mapMutations('GlobalStore', ['setRobotStatus']),
      getRedirectionUrl() {
        return Cookies.get("last-visited-page") || Cookies.get("redirection_path");
      },
      verifyCaptcha(response) {
        http
          .post('/verify_recaptcha.json', { response })
          .then((res) => {
            if (res.data.success) {
              this.setRobotStatus(false);
              this.submitUser();
            } else {
              this.isSubmitting = false;
              this.emitError("Sorry silly robot, this app is for humans! (If you're a human and believe you're seeing this in error, please contact our <a href='mailto:support@gogenuity.com' class='text-white text-underline'>support team</a>.");
              this.resetCaptcha();
            }
          })
          .catch(() => {
            this.isSubmitting = false;
            this.resetCaptcha();
          });
      },
      captchaExpired() {
        this.resetCaptcha();
        this.setRobotStatus(true);
      },
      captchaError(error) {
        if (window.Bugsnag && Bugsnag.notify) {
          Bugsnag.notify(error);
        }
        this.emitError('Sorry, we are having some trouble verifying with reCAPTCHA. Please <a href="javascript:window.location.reload(true)" class="text-underline text-white">refresh</a> and try again, or reach out to our <a href="mailto:support@gogenuity.com" class="text-underline text-white">support team</a>.');
      },
      resetCaptcha() {
        if (this.runCaptcha && this.$refs.recaptcha) {
          this.$refs.recaptcha.reset();
        }
      },
      submituserWithAccessId() {
        const { search, hostname } = window.location;
        if (search.includes("access_id") && hostname.startsWith('secure.')) {
          const searchParams = new URLSearchParams(search);
          const token = this.sanitizeHTML(searchParams.get("access_id"));
          if (token) {
            this.authenticating = true;
            const userParams = { params_token: token };
            if (this.state) {
              userParams.state = this.sanitizeHTML(this.state);
            }
            this.submitUserData(userParams);
          }
        }
      },
      submitUser(e) {
        if (e && e.preventDefault) {
          e.preventDefault();
        }
        const sanitizedEmail = this.sanitizeHTML(this.email);
        const sanitizedPassword = this.sanitizeHTML(this.password);

        const userParams = {
          email: sanitizedEmail,
          password: sanitizedPassword,
        };

        if (this.state) {
          userParams.state = this.sanitizeHTML(this.state);
        }

        this.$validator.validateAll().then(result => {
          if (result) {
            this.isSubmitting = true;
            this.submitUserData(userParams);
          } else {
            this.isSubmitting = false;
            this.resetCaptcha();
            this.emitError(`Please correct errors in the form before submitting.`);
          }
        });
      },
      submitUserData(userParams) {
        http
          .post('/users/sign_in', { userParams })
          .then(res => {
            Cookies.remove("redirection_path");
            sessionStorage.removeItem("redirection_state");
            window.location = res.data.secureUrl ? this.sanitizeHTML(res.data.secureUrl) : this.sanitizeHTML(res.data.url);
            this.resetCaptcha();
            this.isSubmitting = false;
            this.isLoading = false;
            this.resetLoadingAndCaptcha();
          })
          .catch(error => {
            this.emitError(`${error.response.data.message}`);
            if (error.response.data?.redirection) {
              window.location.href = `${new URL(window.location.href).origin}/users/sign_in`;
            }
            this.email = '';
            this.password = '';
            this.resetLoadingAndCaptcha();
          });
      },
      resetLoadingAndCaptcha() {
        this.resetCaptcha();
        this.isSubmitting = false;
        this.isLoading = false;
      },
      gsuiteLogin() {
        if (this.state) {
          window.location = `${this.gsuiteLoginPath}&state=${this.state}`;
        } else {
          window.location = this.gsuiteLoginPath;
        }
      },
      microsoftLogin() {
        window.location = `${this.$cognitoBaseUri}/oauth2/authorize?identity_provider=${this.companySubdomain}&redirect_uri=${this.$cognitoRedirectUri}&response_type=CODE&client_id=${this.$cognitoClientId}&scope=aws.cognito.signin.user.admin email openid profile`;
      },
      fetchGsuiteLoginPath() {
        return http
          .get('/integration_configurations.json', { params: { key: "gsuite_signup" } })
          .then(({ data: { value }}) => {
            this.gsuiteLoginPath = value;
          })
          .catch((error) => {
            this.emitError(`Sorry, there was an error fetching Google Workspace signup path (${error.response.data.message}).`);
          });
      },
      getDomainFromCookies() {
        const cookies = document.cookie;
        if (this.$currentCompanySubdomain) {
          this.companySubdomain = this.$currentCompanySubdomain;
        } else if (cookies.includes("ms_sso_company_subdomain")) {
          cookies.split(';').forEach((cookie) => {
            const splittedCookie = cookie.split('=');
            if (splittedCookie[0]?.replace(/\s/g, '') === "ms_sso_company_subdomain") {
              this.companySubdomain = splittedCookie[1].replace(/\s/g, '');
            }
          });
        }
        this.fetchMicrosoftSSOConfiguration();
      },
      fetchMicrosoftSSOConfiguration() {
        if (this.companySubdomain) {
          return http
            .get('/microsoft_sso_configuration.json', { params: { subdomain: this.companySubdomain } })
            .then((res) => {
              this.microsoftSSOEnabled = res.data.ssoEnabled;
            });
        }

        return null;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .login-container {
    min-height: 100vh;
  }
  .btn-primary {
    min-height: 50px;
  }
  .show-password {
    position: absolute;
    top: 50px;
    display: block;
    right: 5px;
    cursor: pointer;
  }
  .gsuite-btn {
    border: 1px solid $themed-fair;
    background: none;
    min-height: 50px;
    &:hover {
      background: $themed-light;
      border-color: $themed-light;
    }
  }
  .signup-google-text:before,
  .signup-google-text:after {
    content: "";
    width: 45%;
    height: 1px;
    position: absolute;
    top: 45%;
    background-color: #ccc;
  }
  .signup-google-text:before {
    left: 0;
  }
  .signup-google-text:after {
    right: 0;
  }
  .already-registered {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }
</style>
